import { axiosInstance } from "../host";

export const sendDataAuth = (data) => {
	const config = {
		method: "POST",
		url: `doctor/med-form/update`,
		data,
	};
	return axiosInstance(config);
};

export const getDataAuth = (url) => {
	const config = {
		method: "GET",
		url,
	};
	return axiosInstance(config);
};

export const getAuthFormData = (id) => {
	const config = {
		method: "GET",
		url: `doctor/med-form/get-forms?user_id=${id}`,
	};
	return axiosInstance(config);
};
