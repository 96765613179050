import React from 'react'

const NotFound = () => {
  return (
    <div key="notfound">
      Not Found
    </div>
  )
}

export default NotFound
