import { EyeOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Form, Modal, Tabs, Upload } from "antd";
import React from "react";
const { TabPane } = Tabs;

const ModalOldOnes = React.memo(
  ({ visible, byIdOldOnes, onCancel, oldforma, fileList }) => {
    return (
      <div>
        <Modal
          cancelText="Close"
          width={1000}
          visible={visible}
          onCancel={onCancel}
          title="Eski medkartalar"
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))",
              gridColumnGap: "1.5rem",
              gridRowGap: "1.5rem",
              width: "100%",
            }}
          >
            <Tabs tabPosition="left" >
              {byIdOldOnes?.map((item, index) => {
                return (
                  <TabPane key={index} tab={item.name}>
                    <Form name="oldform" form={oldforma}>
                      <Form.Item key={index} name="oldOnes">
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.content}
                          onChange={(_, editor) => editor.getData()}
                        />
                      </Form.Item>
                    </Form>
                  </TabPane>
                );
              })}
              <TabPane tab="Uploaded files" key="1000" >
                {fileList.length !== 0 ? (
                  <Upload
                    style={{ width: "600px" }}
                    multiple
                    listType="picture"
                    showUploadList={{
                      showPreviewIcon: true,
                      previewIcon: <EyeOutlined />,
                    }}
                    fileList={fileList}
                  />
                ) : (
                  <p>No Uploaded files</p>
                )}
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </div>
    );
  }
);

export default ModalOldOnes;
