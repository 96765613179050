import React, { useEffect, useState } from "react";
import { Button, Radio, Table } from "antd";
import { DoctorSchedule, DoctorScheduleMakeBusy } from "../server/config/schedule";
import { getCookie, setCookie } from "../functions/useCookies";
import moment from "moment";
import { convert_number_into_time } from '../functions/convertingNumberIntoTime'
import '../style/home.css'

const HomeP = () => {
  const [doctorSchedule, setDoctorSchedule] = useState([]);
  const [timeTableData, setTimeTableData] = useState([])
  const [Times, setTimes] = useState([]);
  const [size, setSize] = useState();
  const [loading, setLoading] = useState(true);
  const handleSizeChange = (e) => {
    setSize(e.target.value);
    setCookie("activeId", e.target.value);
    let data = []

    let start_time = timeTableData?.start_time?.split(':').reduce(function (seconds, v) {
      return +v + seconds * 60;
    }, 0) / 3600;
    let end_time = timeTableData?.end_time?.split(':').reduce(function (seconds, v) {
      return +v + seconds * 60;
    }, 0) / 3600;

    for (let i = start_time; i <= end_time; i++) {
      data.push(convert_number_into_time(i))
    }
    let element = doctorSchedule.find(
      (el) => el.weekday === e.target.value
    )
    data = data.map((item, index) => {
      return {
        key: index,
        time: item,
        Status: element?.busy_times?.includes(item) ? 'RESERVED' : element?.yellow_times?.includes(item) ? 'APPROVED' : element?.red_times?.includes(item) ? "ORDERED" : element?.doctor_busy_times?.includes(item) ? "BUSY" : "EMPTY",
      };
    });
    setTimes(data);
  };

  const isBusy = (el) => {
    const formData = new FormData()
    const times2 = Times;
    times2?.forEach((item) => {
      let active = getCookie('activeId')
      if (item.time === el.time) {
        if (item.Status === "EMPTY") {
          item.Status = "BUSY";
          doctorSchedule?.forEach((i) => {
            if (i.weekday === active && item.Status === 'BUSY') {
              formData.append('date', i.date + " " + moment(item.time, 'HH:mm').format('HH:mm'))
            }
          })
        } else {
          item.Status = "EMPTY";
          doctorSchedule?.forEach((i) => {
            if (i.weekday === active && item.Status === 'EMPTY') {
              formData.append('date', i.date + " " + moment(item.time, 'HH:mm').format('HH:mm'))
            }
          })
        }
      }
    });
    setTimes(times2);
    DoctorScheduleMakeBusy(formData).then((res) => {
      GetSchedule();


    });
  };

  const columns = [
    {
      title: "Vaqti",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Holati",
      key: "data",
      render: (data) => (
        <>
          <Button
            onClick={() => isBusy(data)}
            type={data.Status === "EMPTY" ? "default" : data.Status === "BUSY" ? "danger" : 'default'}
            className={data.Status === 'ORDERED' ? "ordered" : data.Status === "RESERVED" ? "reserved" : data.Status === "APPROVED" ? "approved" : ""}
          >
            {data.Status === 'ORDERED' ? 'Buyurtma qilingan' :
              data.Status === "RESERVED"
                ? "Zaxiraga olingan"
                : data.Status === "APPROVED"
                  ? "Rozi bo'lgan"
                  : data.Status === "EMPTY"
                    ? "Bo'sh"
                    : "Band"}
          </Button>
        </>
      ),
    },
  ];


  const GetSchedule = () => {

    DoctorSchedule().then((res) => {
      setDoctorSchedule(res.data.weekdays);
      setTimeTableData(res.data)
      let active = getCookie("activeId");
      setSize(active);
      let data = []
      let start_time = res.data?.start_time?.split(':').reduce(function (seconds, v) {
        return +v + seconds * 60;
      }, 0) / 3600;
      let end_time = res.data?.end_time?.split(':').reduce(function (seconds, v) {
        return +v + seconds * 60;
      }, 0) / 3600;

      for (let i = start_time; i <= end_time; i++) {
        data.push(convert_number_into_time(i))
      }


      let element = res.data?.weekdays?.length > 0
        ? res.data?.weekdays?.find(
          (el) => el.weekday === active
        ) : {}
      data = data.map((item, index) => {
        return {
          key: index,
          time: item,
          Status: element?.busy_times?.includes(item) ? 'RESERVED' : element?.yellow_times?.includes(item) ? 'APPROVED' : element?.red_times?.includes(item) ? "ORDERED" : element?.doctor_busy_times?.includes(item) ? "BUSY" : "EMPTY",
        };
      });
      setTimes(data);
      if (data) {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    GetSchedule()
  }, []);

  return (
    <React.Fragment>
      <div className="w-100">
        <div className="date-box mb-2">
          <Radio.Group
            buttonStyle="solid"
            value={size}
            onChange={handleSizeChange}
            defaultValue={getCookie("activeId") ? getCookie('activeId') : ''}
          >
            {doctorSchedule?.map((item) => (
              <Radio.Button
                key={item.weekday}
                value={item.weekday}
              >
                {item.date}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <Table
            pagination={false}
            tableLayout="fixed"
            bordered
            size="small"
            columns={columns}
            scroll={{ x: 200 }}
            className="w-100"
            dataSource={Times}
            loading={loading}
          ></Table>

        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeP;
