import { setPageData } from "../../redux/actions";
import { deleteT, editTemp, getTemp, getTempSave, sendTemp } from "./config/template";
import { editTempObj, getTempObj, getTempObjSave, sendTempObj } from "./config/tempObj";

// template
export const createTemp = (dispatch, data) => {

	sendTemp(data).then(() => {
		getTempSave().then(({ data }) => {
			if (data) {
				dispatch(setPageData({ content: data.data, total: data.total }));
			}
		});
	});
};

export const readTemp = (dispatch, page) => {
	getTemp(page).then(({ data }) => {
		if (data) {
			dispatch(setPageData({ content: data.data, total: data.total }));
		}
	});
};

export const readTempSave = (dispatch, page) => {
	getTempSave(page).then(({ data }) => {
		if (data) {
			dispatch(setPageData({ content: data.data, total: data.total }));
		}
	});
};

export const updateTemp = (dispatch, data,id) => {


	editTemp(data,id).then(() => {
		getTempSave().then(({ data }) => {
			if (data) {
				dispatch(setPageData({ content: data.data, total: data.total }));
			}
		});
	});
};

export const deleteTemp = (dispatch, id) => {
	deleteT(id).then(() => {
		getTempSave().then(({ data }) => {
			if (data) {
				dispatch(setPageData({ content: data.content, total: data.totalElements }));
			}
		});
	});
};

// template

// template object
export const createObj = (dispatch, data, isrender = true) => {
	return sendTempObj(data).then(res => {
		if (isrender)
			getTempObj().then(({ data }) => {
				if (data) {
					dispatch(setPageData({ content: data.data, total: data.total }));
				}
			});
		return res.data;
	});
};

export const readTempObj = (dispatch, page) => {
	getTempObj(page).then(({ data }) => {
		if (data) {
			dispatch(setPageData({ content: data.data, total: data.total }));
		}
	});
};

export const readTempObjSave = (dispatch, page) => {
	getTempObjSave(page).then(({ data }) => {
		if (data) {
			dispatch(setPageData({ content: data.data, total: data.total }));
		}
	});
};

export const updateTempObj = (dispatch, data, type, id) => {
	editTempObj(data, id).then(() => {
		type === 'saved' ? getTempObjSave().then(({ data }) => {
			if (data) {
				dispatch(setPageData({ content: data.data, total: data.total }));
			}
		}) : getTempObj().then(({ data }) => {
			if (data) {
				dispatch(setPageData({ content: data.data, total: data.total }));
			}
		});
	});
};


// template object
