import { axiosInstance } from "../host";

export const DoctorSchedule = () => {
  const config = {
    method: "get",
    url: `doctor/time-table/get-list`,
  };
  return axiosInstance(config);
};

export const DoctorScheduleMakeBusy = (data) => {
  const config = {
    method: "post",
    url: `doctor/time-table/set-busy-time`,
    data,
  };
  return axiosInstance(config);
};
