import { axiosInstance } from "../../host";

export const getTemp = (page) => {
	const config = {
		method: "GET",
		url: `/doctor/full-templates/get-list?page=${page}`,
	};
	return axiosInstance(config);
};

export const getTempSave = (page) => {
	const config = {
		method: "GET",
		url: `/doctor/full-templates/get-list?type=personal&page=${page}`,
	};
	return axiosInstance(config);
};

export const getTempeditData = (id) => {
	const config = {
		method: "GET",
		url: `/doctor/full-templates/show/${id}`,
	};
	return axiosInstance(config);
};
export const getTempFormData = (id) => {
	const config = {
		method: "GET",
		url: `/doctor/full-templates/show/${id}`,
	};
	return axiosInstance(config);
};

export const sendTemp = (data) => {
	const config = {
		method: "POST",
		url: `/doctor/full-templates/store`,
		data,
	};
	return axiosInstance(config);
};

export const editTemp = (data, id) => {
	const config = {
		method: "POST",
		url: `/doctor/full-templates/update/${id}`,
		data,
	};
	return axiosInstance(config);
};

export const deleteT = (id) => {
	const config = {
		method: "DELETE",
		url: `/template/delete/${id}`,
	};
	return axiosInstance(config);
};

export const filterTemp = (categoryId, type, p) => {
	const config = {
		method: "GET",
		url: `/doctor/full-templates/get-list?category_id=${categoryId}&type=${type}&page=${p}`,
	};
	return axiosInstance(config);
};
