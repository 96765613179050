import { axiosInstance } from "../../host";

export const getCategory = () => {
	const config = {
		method: "GET",
		url: `/doctor/template-categories/get-list`,
	};
	return axiosInstance(config);
};

export const getSubCategory = (id) => {
	const config = {
		method: "GET",
		url: `/doctor/template-categories/get-list?category_id=${id}`,
	};
	return axiosInstance(config);
};
