import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Divider,
  message,
} from "antd";
import {
  getAuthFormData,
  sendDataAuth,
} from "../server/config/common";
const UserMedForm = ({ userMedForm, userId }) => {
  const [buttonLoading] = useState(false);
  const [userMedData, setUserMedData] = useState([])
  useEffect(() => {

    getAuthFormData(userId).then((res) => {
      setUserMedData(res.data)
    });
  }, [userId, userMedForm]);

  const onFinish = (values) => {
    let formData = new FormData();
    Object.entries(values).forEach(([id, value], index) => {
      let [[key, val]] = Object.entries(value);
      val && formData.append(`answers[${index}][${key}]`, val);
      val && formData.append(`answers[${index}][med_question_id]`, id);
    });
    formData.append('user_id', userId)
    sendDataAuth(formData).then((res) => {
      if (res) {
        message.success("Muvaffaqiyatli yangilandi")
      }
    }).catch((err) => {
      message.error(err.message)

    })
  };

  return (
    <Col span={24}>
      <div className="card flex-grow-1">
        <div className="card-body">
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={userMedForm}
            onFinish={onFinish}
            name="medInfo"
          >
            {
              userMedData?.map((userMedataItem) => {
                return (
                  <>
                    <h4 className="text-center" key={userMedataItem.id}>{userMedataItem.name}</h4>
                    <Divider />
                    <Row gutter={[8, 8]}>
                      {userMedataItem.questions?.map((questionItem) => {
                        return (
                          <>
                            {questionItem.answer_type === "text" && (
                              <Col md={12} span={24}>
                                <Form.Item
                                  label={questionItem.question}
                                  name={[questionItem.id, "answer"]}
                                  initialValue={
                                    questionItem.user_answers.length !== 0 &&
                                    questionItem.user_answers[0].answer
                                  }
                                >
                                  <Input placeholder={questionItem.question} />
                                </Form.Item>
                              </Col>
                            )}

                            {questionItem.answer_type === "select" && (
                              <Col md={12} span={24}>
                                <Form.Item
                                  label={questionItem.question}
                                  name={[questionItem.id, "med_question_answer_id"]}
                                  initialValue={
                                    questionItem.user_answers.length !== 0 &&
                                    questionItem.user_answers[0].med_question_answer_id
                                  }
                                >
                                  <Select placeholder={questionItem.question}>
                                    {questionItem.med_question_answers.map((ans) => (
                                      <Select.Option
                                        value={ans.id}
                                        key={ans.answer}
                                      >
                                        {ans.answer}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        );
                      })}


                    </Row>
                  </>
                )
              })
            }

            <div className="d-flex justify-content-between">
              <Form.Item key="submit">
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={buttonLoading}
                  disabled={buttonLoading}
                >
                  Saqlash
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Col>
  );
};

export default UserMedForm;
