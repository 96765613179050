import { axiosInstance } from "../host";
export const TreatedGet = (page) => {
	const config = {
		method: "get",
		url: `doctor/orders/get-completed-list?page=${page}`,
	};
	return axiosInstance(config);
};

export const GetMedCardById = (medCardId) => {
	const config = {
		method: "get",
		url: `doctor/orders/get-med-cards/${medCardId}`,
	};
	return axiosInstance(config);
};
export const GetAllUserMedCardEnabled = (userId,p) => {
	const config = {
		method: "get",
		url: `doctor/orders/get-med-cards?user_id=${userId}&page=${p}`,
	};
	return axiosInstance(config);
};

export const UserUnfinishedMedCardsGet = (page) => {
	const config = {
		method: "get",
		url: `doctor/orders/get-progress-list?page=${page}`,
	};
	return axiosInstance(config);
};

export const UserUnfinishedMedCardsEdit = (data) => {
	const config = {
		method: "post",
		url: `med-card/edit`,
		data,
	};
	return axiosInstance(config);
};

export const UnFinishedDeleteGet = (orderId) => {
	const config = {
		method: "get",
		url: `med-card/unfinished/change?orderId=${orderId}`,
	};
	return axiosInstance(config);
};
