import { axiosInstance } from "../../host";

export const getTempObj = (page = 1) => {
	const config = {
		method: "GET",
		url: `/doctor/templates/get-list?page=${page}`,
	};
	return axiosInstance(config);
};

export const getTempObjSave = (page) => {
	const config = {
		method: "GET",
		url: `/doctor/templates/get-list?type=personal&page=${page}`,
	};
	return axiosInstance(config);
};

export const getTOeditData = (id) => {
	const config = {
		method: "GET",
		url: `/doctor/templates/show/${id}`,
	};
	return axiosInstance(config);
};

export const sendTempObj = (data) => {
	const config = {
		method: "POST",
		url: `/doctor/templates/store`,
		data,
	};
	return axiosInstance(config);
};

export const editTempObj = (data,id) => {
	const config = {
		method: "POST",
		url: `/doctor/templates/update/${id}`,
		data,
	};
	return axiosInstance(config);
};

export const deleteTO = (id) => {
	const config = {
		method: "DELETE",
		url: `/template-object/delete/${id}`,
	};
	return axiosInstance(config);
};

export const filterTempObj = (categoryId, type,p) => {
	const config = {
		method: "GET",
		url: `/doctor/templates/get-list?category_id=${categoryId}&type=${type}&page=${p}`,
	};
	return axiosInstance(config);
};
