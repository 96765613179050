import axios from "axios";
import { API_URL, headers, editHeaders } from "../assets/constants";

export let axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  headers,
  timeout: 100000,
});
export let editAxiosInstance = axios.create({
  baseURL: `${API_URL}`,
  editHeaders,
  timeout: 100000,
});
