import React from "react";
import { Modal, Form, message, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { closeInsideModal, setCkEditor } from "../../../redux/actions";
import { createObj, updateTempObj } from "../../../server/Template/actionCreator";
import CustomItem from "./CustomItem";

const bodyStyle = {
	height: "100%",
};

const InsideModal = React.memo(({ insideForm, MCData, setMCData, type, setStatus, status }) => {
	const {
		modal: {
			InsideM: { visible, title, action, id, isreturn, index },
		},
		data: { ckEditor = "<p></p>" },
	} = useSelector(state => state);
	const dispatch = useDispatch();
	const OnSAVE = () => {
		insideForm
			.validateFields()
			.then(val => {
				if (action === "createObj") {
					if (isreturn) {
						const formData = new FormData()
						formData.append('name', val?.name);
						formData.append('description', val?.description);
						formData.append('content', val?.content);
						formData.append('template_category_id', val?.template_category_id);
						formData.append('status', status);
						createObj(dispatch, formData, false).then(res => {
							if (res) {
								let newList = [...MCData];
								newList.splice(index, 1, res);
								setMCData(newList);
							}
						});
					} else {
						const formData = new FormData()
						formData.append('name', val?.name);
						formData.append('description', val?.description);
						formData.append('content', val?.content);
						formData.append('template_category_id', val?.template_category_id);
						formData.append('status', status);

						createObj(dispatch, formData)
					};
				} else if (action === "updateObj") {
					let Type = type
					const formData = new FormData()
					formData.append('name', val?.name);
					formData.append('description', val?.description);
					formData.append('content', val?.content);
					formData.append('template_category_id', val?.template_category_id);
					formData.append('status', status);
					updateTempObj(dispatch, formData, Type, id)
				};
			})
			.then((data) => {
				onCancel();
				if (data) {
					message.success("Saqlandi", [0.6]);
				}
			})
			.catch(() => {
				message.error("xato bor", [0.5]);
			});
	};

	const onCancel = () => {
		dispatch(closeInsideModal());
		insideForm.resetFields();
		if (ckEditor !== "<p></p>") dispatch(setCkEditor("<p></p>"));
	};
	const onChange = () => {
		setStatus(0)
	}
	return (
		<Modal
			centered
			bodyStyle={bodyStyle}
			width="95%"
			title={title}
			visible={visible}
			onCancel={onCancel}
			onOk={OnSAVE}
			okText={"Сохранить"}
			cancelText={"Отмена"}
			className="template-modal">
			<Form form={insideForm} layout="vertical" name="form_in_modal_INSIDE">
				<CustomItem />
				<Form.Item
					noStyle
					key="context"
					name="content"
					label="Malumotlar"
					getValueFromEvent={(_, editor) => editor.getData()}>
					<CKEditor data={ckEditor} editor={ClassicEditor} />
				</Form.Item>
				<Form.Item key="status" >
					<Switch checked={status === 0} onChange={() => onChange()} />
				</Form.Item>
			</Form>
		</Modal>
	);
});

export default InsideModal;
