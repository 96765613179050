import React from "react";
import { Modal, Form, message, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeDefModal } from "../../../redux/actions";
import DragAndDrop from "../DragAndDrop";
import CustomItem from "./CustomItem";
import { createTemp, updateTemp } from "../../../server/Template/actionCreator";

const bodyStyle = {
	height: "100%",
};

const DefModal = React.memo(({ defForm, MCData, setMCData, insideForm, status, setStatus,sort,setSort }) => {
	const dispatch = useDispatch();
	const { visible, title, action, id } = useSelector(state => state.modal.defaultM);
	
	const OnSAVE = () => {
		defForm
			.validateFields()
			.then(val => {
				if (action === "createTemp") {
					const formData = new FormData()
					formData.append('name', val?.name);
					formData.append('description', val?.description);
					formData.append('template_category_id', val?.template_category_id);
					formData.append('status', status);
					MCData?.forEach((item, index) => {
						formData.append(`templates[${index}][status]`, item?.status);
						formData.append(`templates[${index}][template_id]`, item?.id);
						formData.append(`templates[${index}][sort]`, sort);

					})

					createTemp(dispatch, formData);
				}
				if (action === "updateTemp") {
					const formData = new FormData()
					formData.append('name', val?.name);
					formData.append('description', val?.description);
					formData.append('template_category_id', val?.template_category_id);
					formData.append('status', status);
					MCData?.forEach((item, index) => {
						formData.append(`templates[${index}][status]`, item?.status);
						formData.append(`templates[${index}][template_id]`, item?.id);
						formData.append(`templates[${index}][sort]`, item.pivot.sort);

					})
					updateTemp(dispatch, formData, id)
				}
			})
			.then((data) => {
				onCancel();
				if (data) {
					message.success("Saqlandi", [0.6]);
				}
			})
			.catch(() => {
				message.error("Qandaydir xatolik yuz berdi", [0.5]);
			});
	};

	const onCancel = () => {
		document.querySelectorAll("#fullTemplate_btn").forEach(el => {
			el.classList.remove("added");
		});
		dispatch(closeDefModal());
		defForm.resetFields();
		if (MCData.length) setMCData([]);
	};
	const onChange = () => {
		setStatus(0)
	}
	return (
		<Modal
			centered
			width={"95%"}
			bodyStyle={bodyStyle}
			visible={visible}
			title={title}
			okText={"Сохранить"}
			cancelText={"Отмена"}
			onCancel={onCancel}
			onOk={OnSAVE}>
			<Form form={defForm} layout="vertical" name="form_in_modal_DEFAULT">
				<CustomItem />
				<Form.Item label="" key="def_form_item">
					<DragAndDrop setSort={setSort} insideForm={insideForm} MCData={MCData} setMCData={setMCData} />
				</Form.Item>
				<Form.Item key="status" >
					<Switch checked={status === 0} onChange={() => onChange()} />
				</Form.Item>
			</Form>
		</Modal>
	);
});

export default DefModal;
