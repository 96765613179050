import React, { useEffect, useState } from "react";
import { Table, Skeleton, Button, Form, Card, Radio, Tabs, Tag, message, Space, Modal, Input, Upload } from "antd";
import { GetAllUserMedCardEnabled, GetMedCardById, UserUnfinishedMedCardsGet } from "../server/config/treated";
import ModalOldOnes from "../comps/ModalOldOnes";
import { DownloadOutlined, FileAddOutlined } from "@ant-design/icons";
import MedCardFilter from "../comps/medCardFilter";
import { TiArrowBackOutline } from "react-icons/ti";
import { getTemp, getTempeditData, getTempSave } from "../server/Template/config/template";
import { orderMedCardCreate } from "../server/config/order";
import UserMedForm from "../comps/UserMedForm";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const { Search } = Input;
const { TabPane } = Tabs;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const ReportsP = () => {
  const [loading, setLoading] = useState(true);
  const [bookingloading, setBookingLoading] = useState(true);
  const [useMedForm] = Form.useForm();
  const [orderObjForm] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [listColumns, setListColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const [index, setIndex] = useState(0);
  const [isdisabled, setisdisabled] = useState(true);
  const [temps, setTemps] = useState([]);
  const [objects, setObjects] = useState([]);
  const [userServisId, setUserServisId] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [showOldMedCars, setshowOldMedCars] = useState(false);
  const [byIdOldOnes, setByIdOldOnes] = useState([]);
  const [usedMedCards, setUsedMedCards] = useState([]);
  const [fileList, setFileList] = useState([]);
  const oldtreatedcolumns = [
    {
      title: 'Klinika',
      dataIndex: 'clinic',
      key: 'clinic',
      render: clinic => <span style={{ display: "flex" }}>{clinic?.name}</span>
    },
    {
      title: "Doktor",
      dataIndex: "doctor",
      key: "doctor",
      render: doctor => <span style={{ display: "flex" }}>{doctor?.user?.fio}</span>
    },

    {
      title: "Xizmat nomi",
      dataIndex: "service",
      key: "service",
      render: service => <span style={{ display: "flex" }}>{service?.name}</span>
    },
    {
      title: "Xizmat vaqti",
      dataIndex: "service",
      key: "service_date",
      render: service => <span style={{ display: "flex" }}>{service?.created_at}</span>
    },
    {
      title: "Ma'lumotlar",
      key: "id",
      dataIndex: "id",
      render: (madCardId, obj) => (
        <Button key={madCardId} onClick={() => batafsilBtn(madCardId, obj)}>
          Batafsil
        </Button>
      ),
    },
  ];

  const studentColumns = [
    {
      title: "Bemor",
      key: "user",
      dataIndex: "user",
      render: user => <span style={{ display: "flex" }}>{user?.name + " " + user?.surname}</span>
    },
    {
      title: "Xizmat nomi",
      key: "service",
      dataIndex: "service",
      render: service => <span style={{ display: "flex" }}>{service?.name}</span>
    },
    {
      title: "Vaqti",
      key: "service_time",
      dataIndex: "service_time",
    },
    {
      title: "Sanasi",
      key: "created_at",
      dataIndex: "created_at",
      render: date => <span style={{ display: "flex" }}>{date?.slice(0, 10)}</span>
    },
    {
      title: "MedKarta",
      key: "id",
      dataIndex: "id",
      render: (id, object) => (
        <Button disabled={object.status === "noactive"} onClick={() => medcardBtn(id, object)}>
          To'ldirish
        </Button>
      ),
    },
  ];

  const batafsilBtn = (id, obj) => {
    setIsShow(true);
    GetMedCardById(id).then(res => {
      setByIdOldOnes(res.data.templates);
      setFileList(res.data?.files)
    });
  };

  const oldOnCancel = () => {
    setIsShow(false);
  };

  const handlePaginationChange = page => {
    setCurrentPage(page);
  };

  const medcardBtn = (id, object) => {
    showModal();
    setUserServisId({ ...object });
  };

  const GetOrders = () => {
    UserUnfinishedMedCardsGet().then(res => {
      setListColumns(res.data.data);
      setCurrentPage(res.data.current_page)
      setTotalElements(res.data.total);
      setBookingLoading(false);
    });
  };

  const StandartTemps = (page) => {
    getTemp(page).then(({ data }) => {
      setTemps(data.data);
    });
  };

  const SavedTemps = (page) => {
    getTempSave(page).then(({ data }) => {
      setTemps(data.data);
    });
  };

  useEffect(() => {
    GetOrders();
    StandartTemps();
    setLoading(false);
  }, [setLoading]);

  const showModal = () => {
    setVisible(true);
  };

  const handleTabChange = key => {
    if (key === "1") {
      StandartTemps();
    } else {
      SavedTemps();
    }
  };

  const next = () => {
    if (selected) {
      orderObjForm
        .validateFields()
        .then(newvalues => {
          const formData = new FormData();
          formData.append('order_id', userServisId?.id);
          objects?.forEach((item, i) => {
            formData.append(`templates[${i}][name]`, item.name);
            formData.append(`templates[${i}][description]`, item.description);
            formData.append(`templates[${i}][template_category_id]`, item.template_category_id);
            formData.append(`templates[${i}][sort]`, item.pivot?.sort);
            formData.append(`templates[${i}][content]`, newvalues.content);

          })
          fileList.forEach((item, i) => {
            formData.append(`files[${i}]`, item.originFileObj)
          })
          orderMedCardCreate(formData);
        })
        .then(() => {
          GetOrders()
          message.success("Muvaffaqiyatli saqlandi!!!", [0.6]);
        })
        .catch(() => {
          message.error("Qandaysdir xato yuzaga keldi.Qayta urinib ko'ring!!!", [0.6]);
        });
      setVisible(false);
    } else {
      setSelected(true);
      let { id } = temps[index];
      getTempeditData(id).then(({ data }) => {
        setObjects(data?.templates);
      });
    }
  };

  const back = () => {
    setSelected("");
    setisdisabled(true);
  };

  const oldPageChange = (p) => {
    GetAllUserMedCardEnabled(userServisId?.user_id, p).then(res => {
      setUsedMedCards(res.data.data);
    });
  };

  const handleCancel = () => {
    if (selected) {
      GetAllUserMedCardEnabled(userServisId?.user_id).then(res => {
        setUsedMedCards(res.data?.data);
        setshowOldMedCars(true);
      });
    } else {
      setVisible(false);
    }
  };


  const handleUploadChange = (info) => {
    let fileListInfo = [...info?.fileList];
    if (info?.fileList.length) {

      setFileList(fileListInfo)
    }

  }

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton key="bookingskeleton" active />
      ) : (

        <Table
          title={() => (
            <Space>
              <Search key={1} placeholder="Поиск" onSearch={value => console.log(value)} style={{ width: 200 }} />
            </Space>
          )}
          pagination={{
            current: currentPage,
            total: totalElements,
            pageSize: 10,
            onChange: handlePaginationChange,
            showTotal: totalElements => `ВСЕ: ${totalElements}`,
          }}
          tableLayout="fixed"
          bordered
          size="small"
          columns={studentColumns}
          dataSource={listColumns}
          rowKey={item => item.orderId}
          scroll={{ x: 800 }}
          className="w-100"
          loading={bookingloading}
        />
      )}
      <Modal
        name="eskikarta"
        key="eskikarta"
        width={1000}
        centered
        visible={showOldMedCars}
        title={"Eski medkartalar ro'yxati"}
        onCancel={() => setshowOldMedCars(false)}>
        <Table
          pagination={{
            current: currentPage,
            total: totalElements,
            pageSize: 10,
            onChange: oldPageChange,
            showTotal: totalElements => `ВСЕ: ${totalElements}`,
          }}
          tableLayout="fixed"
          bordered
          size="small"
          dataSource={usedMedCards}
          rowKey={e => e.id}
          columns={oldtreatedcolumns}
        />
      </Modal>
      <ModalOldOnes
        fileList={fileList}
        name="oldones"
        key="oldones"
        visible={isShow}
        byIdOldOnes={byIdOldOnes}
        onCancel={oldOnCancel}
      />
      <Modal
        name="mainMOdal"
        key="mainMOdal"
        onOk={next}
        okButtonProps={{ disabled: isdisabled }}
        onCancel={handleCancel}
        okText={selected ? "Сохранить" : "Следующий"}
        cancelText={selected ? "предыдущие медицинские карты" : "отмена"}
        visible={visible}
        closable={false}
        maskClosable={false}
        title={[
          <div>
            {selected && (
              <Tag onClick={back}>
                <TiArrowBackOutline />
              </Tag>
            )}
            <span>Медицинская карта пациента</span>
          </div>,
        ]}
        className="w-100">
        {selected ? (
          <Tabs tabPosition="left">
            <TabPane tab="Информация о пациенте" key="1">
              <UserMedForm userId={userServisId.user_id} useMedForm={useMedForm} />
            </TabPane>
            {objects.map((item, index) => (
              <TabPane forceRender={true} tab={item.name} key={index + 3}>
                <Form name="useMedForm" form={orderObjForm} {...layout}>
                  <Form.Item
                    initialValue={item.content}
                    noStyle
                    name={`content`}
                    key={`content/${index}`}
                    getValueFromEvent={(_, editor) => editor.getData()}>
                    <CKEditor editor={ClassicEditor} data={item.content} />
                  </Form.Item>
                </Form>
              </TabPane>
            ))}
            <TabPane tab="File upload" key="1000">
              <Upload
                multiple
                fileList={fileList}
                listType="picture"
                onChange={handleUploadChange}
                beforeUpload={() => false}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: <DownloadOutlined />,
                }}>
                <Button type="primary">Upload</Button>
              </Upload>
            </TabPane>
          </Tabs>
        ) : (
          <div>
            <MedCardFilter setTemps={setTemps} />
            <Radio.Group
              buttonStyle="solid"
              className="w-100"
              size="middle"
              onChange={e => {
                setIndex(e.target.value);
                setisdisabled(false);
              }}>
              <Tabs defaultActiveKey="1" centered onChange={handleTabChange}>
                <TabPane tab="Стандарт" key="1">
                  <div className="booking_modal_cont">
                    {temps?.map((item, index) => (
                      <Card
                        key={index}
                        title={
                          <div className="temp_maincont_card_head">
                            <span className="temp_maincont_card_tit">{item.name}</span>
                            <Radio.Button key={index + 50} value={index}>
                              <FileAddOutlined />
                            </Radio.Button>
                          </div>
                        }
                        className="temp_card"
                        hoverable>
                        {item.description}
                      </Card>
                    ))}
                  </div>
                </TabPane>
                <TabPane tab="Сохранено" key="2">
                  <div className="booking_modal_cont">
                    {temps.map((item, index) => (
                      <Card
                        key={index}
                        title={
                          <div className="temp_maincont_card_head">
                            <span className="temp_maincont_card_tit">{item.name}</span>
                            <Radio.Button key={index + 100} value={index}>
                              <FileAddOutlined />
                            </Radio.Button>
                          </div>
                        }
                        className="temp_card"
                        hoverable>
                        {item.description}
                      </Card>
                    ))}
                  </div>
                </TabPane>
              </Tabs>
            </Radio.Group>
          </div>
        )}
      </Modal>

    </React.Fragment>
  );
};
export default ReportsP;
