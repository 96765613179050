
import { axiosInstance } from '../host';
export const loginUser = (data) => {
  const config = {
    method: "POST",
    url: "doctor/login",
    data,
  };
  return axiosInstance(config)
  
};