export const PAGEDATA = "PAGEDATA";
export const CKEDITOR = "CKEDITOR";
export const DRAGDROP = "DRAGDROP";
// data

// modal
export const DEFMODAL = "DEFMODAL";
export const CLOSEDEFMODAL = "CLOSEDEFMODAL";
export const CLOSEINSIDEMODAL = "CLOSEINSIDEMODAL";
export const INSIDEMODAL = "INSIDEMODAL";
// modal
